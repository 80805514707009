@font-face {
  font-family: Gotham;
  src: local(GothamBook), url(../../fonts/Gotham-Book.woff2) format('woff2'),
    url(../../fonts/Gotham-Book.woff) format('woff'),
    url(../../fonts/Gotham-Book.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Gotham;
  src: local(GothamMedium), url(../../fonts/Gotham-Medium.woff2) format('woff2'),
    url(../../fonts/Gotham-Medium.woff) format('woff'),
    url(../../fonts/Gotham-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Gotham;
  src: local(GothamBold), url(../../fonts/Gotham-Bold.woff2) format('woff2'),
    url(../../fonts/Gotham-Bold.woff) format('woff'),
    url(../../fonts/Gotham-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Gotham;
  src: local(GothamBlack), url(../../fonts/Gotham-Black.woff2) format('woff2'),
    url(../../fonts/Gotham-Black.woff) format('woff'),
    url(../../fonts/Gotham-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Austin;
  src: local(GothamBlack),
    url(../../fonts/Austin-MediumItalic.woff2) format('woff2'),
    url(../../fonts/Austin-MediumItalic.woff) format('woff'),
    url(../../fonts/Austin-MediumItalic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
}

@import 'social-font';
