// Spacing
$page__padding: var(--page__padding);

// Css vars
:root {
  --page-padding: 40px;
  @media (max-width: 1249px) {
    --page-padding: 30px;
  }
  @media (max-width: 989px) {
    --page-padding: 24px;
  }
  @media (max-width: 767px) {
    --page-padding: 16px;
  }
}
