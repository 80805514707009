// Css vars
:root {
  --height__telefonica: 100px;
  --top-band-height: flex-height(40);
  @include respond-to(tall) {
    --top-band-height: 20px;
  }
  // Estas variables se emplearan para reducir el menu
  --height__header: 20vh;
  --height__header-small: 15vh;
}

$top-band-height: var(--top-band-height);
$height__telefonica: var(--height__telefonica);
$height__header: var(--height__header);
$height__header-small: var(--height__header-small);
